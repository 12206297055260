// plugins/axios.ts
import { Plugin } from '@nuxt/types'
import { AxiosInstance } from 'axios'
import { __init__ } from '@/utils/compositions/useAxios'

const axiosPlugin: Plugin = ({ req, $axios }) => {
  let clientIp = ''

  if (process.server && req) {
    // Type assertions to string, if available.
    clientIp = (req.headers['cf-connecting-ip'] as string) ||
      (req.headers['x-forwarded-for'] as string) ||
      req.connection?.remoteAddress ||
      ''
  }

  $axios.onRequest((config) => {
    if (process.server) {
      config.headers['X-Internal-Request'] = 'true'
    }

    if (clientIp) {
      // Set custom headers with the real client IP
      config.headers['X-Real-IP'] = clientIp
      config.headers['X-Forwarded-For'] = clientIp
    }
    return config
  })

  // Initialize our composable with the enhanced Axios instance
  __init__($axios as AxiosInstance)
}

export default axiosPlugin
