import useApi, { UseApiOptions } from '../compositions/useApi'
import dataTransformer from '~/utils/transformers/data-transformer'

export interface IProductSearchRequestData {
  page: number | null
  count: number | null
  brand: string[] | null
  category: string[] | null
  price: null | {
    from: number | null
    to: number | null
  }
  sort: null | {
    column: 'date' | 'price' | null
    direction: 'asc' | 'desc'
  }
  query: null | {
    language_code: string
    title: string
  }
  filters: null | {
    slug: string
    values: string[]
  }[]
}

export const useApiGetSearchProductFilter = (opts?: UseApiOptions) =>
  useApi<IProductSearchRequestData, any>(
    ({ page, count, brand, category, price, sort, query, filters }) => ({
      method: 'POST',
      url: '/search/product-filter',
      data: { page, count, brand, category, price, sort, query, filters },
    }),
    opts,
  )
